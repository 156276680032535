import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";


const ServiceList = [
    {
        icon: <FiLayers />,
        title: 'Audit légal (commissariat aux comptes)',
        description: 'Nous garantissons les lois et normes communautaires ; sous régionales, et internationales.'
    },
    {
        icon: <FiLayers />,
        title: 'Audit interne',
        description: `Nous évaluons l'efficacité, l'efficience et la conformité d'une organisation aux contrôles internes et à la gestion des risques.`
    },
    {
        icon: <FiLayers />,
        title: 'Audit de projets appuyés par des bailleurs de fonds internationaux',
        description: `Il s’agit de garantir la transparence et la traçabilité dans l’utilisation des fonds.`
    },
    {
        icon: <FiLayers />,
        title: 'Audit Contractuel',
        description: `Un audit contractuel évalue la performance et la conformité des parties contractantes, garantissant le respect des termes du contrat.`
    },
    {
        icon: <FiLayers />,
        title: `Audit du systeme d'information`,
        description: `Les audits de systèmes d'information permettent d'évaluer la sécurité, la fiabilité et l'efficacité de l'infrastructure informatique d'une organisation.`
    },
    {
        icon: <FiLayers />,
        title: `Audit en situation d'acquisition, fusion ou scission de sociétes`,
        description: `Evaluer les impacts financiers combinés, assurer la conformité réglementaire et à identifier les risques potentiels, en fournissant des informations cruciales pour la prise de décision.'`
    },
    {
        icon: <FiLayers />,
        title: 'Audit des passations de marchés',
        description: `Examiner les activités, les processus et les contrôles d'une organisation en matière d'achats et de chaîne d'approvisionnement.`
    },
    {
        icon: <FiLayers />,
        title: 'Mission de procédures convenues',
        description: `L'objectif est de fournir l'assurance raisonnable que la procédure spécifique, convenue d'un commun accord par les partenaires comme prévu.`
    },
    {
        icon: <FiLayers />,
        title: 'Audit social',
        description: `Un audit social est un processus qui évalue les impacts sociaux et éthiques des opérations et des activités d'une institution.`
    },
 ]

const ServiceListOne = [
    {
        icon: <FiLayers />,
        title: 'Plan comptable',
        description: `Nous effectuons des audits afin dévaluer les contrôles internes, d'évaluer les risques et d'identifier les problèmes susceptibles daffecter la santé financière de l'entreprise.`
    },
    {
        icon: <FiLayers />,
        title: 'Tenue de la comptabilité',
        description: `CAIRQ CONSEIL examine les registres financiers, préparera et analysera les états financiers et cernera les sujets de préoccupation potentiels.`
    },
    {
        icon: <FiLayers />,
        title: 'Organisation et methodes comptables',
        description: `Une organisation et des méthodes comptables bien structurées peuvent aider les entreprises à rationaliser leurs opérations, à améliorer leur efficacité et à mieux gérer les risques financiers.`
    },
    {
        icon: <FiLayers />,
        title: 'Document de synthese et comptes annuels',
        description: `Un document synthese, aussi appelé synthese financier, fournit un aperçu concis des indicateurs clés de performance financière dune organisation.`
    },
    {
        icon: <FiLayers />,
        title: 'Comptabilité générale',
        description: `Cela implique de tenir des régistres précis de toutes les données financières, y compris les ventes, les achats, la paie et les dépenses.`
    },
    {
        icon: <FiLayers />,
        title: 'Déclarations statistiques et fiscales',
        description: `En combinant les déclarations statistiques et fiscales, nous pouvons fournir une mine d'informations aux autorités fiscales et aux entreprises, ce qui permet un système fiscal plus efficace et plus éclairé.`
    },
    
    
]

const ServiceListThree = [
    
    
    {
        icon: <FiLayers />,
        title: 'Procédures administratives, financières et comptables',
        description: `Ces procédures aident à planifier, organiser, diriger et contrôler les ressources de l'entreprise.`
    },
    {
        icon: <FiLayers />,
        title: 'Restructation de sociétes',
        description: `L'objectif est de réaligner les ressources et les opérations de l'entreprise de manière à ce quelle soit mieux positionnée pour la croissance et le succès à long terme.`
    },
    
    
]

const ServiceListFour = [
    {
        icon: <FiLayers />,
        title: 'Constitution dune entreprise',
        description: `Lorsqu'une société est constituée, elle acquiert une identité juridique distincte de celle de ses actionnaires, administrateurs et dirigeants.`
    },
    {
        icon: <FiLayers />,
        title: `Secrétariat et assemblées générales`,
        description: 'Nous jouons un rôle crucial dans la facilitation et le bon déroulement des conseils dadministration et des assemblées générales.'
    },
    {
        icon: <FiLayers />,
        title: `Travaux d'arbitrage`,
        description: `Nous vous guidons à travers les différentes étapes du processus d'arbitrage, de l'introduction d'une procédure à l'exécution d'une sentence.`
    },
    {
        icon: <FiLayers />,
        title: 'Restructuration des entreprises',
        description: `La restructuration d'entreprise consiste à réorganiser la structure juridique et financière d'une entreprise, généralement dans le but d'optimiser l'efficacité, la productivité et la rentabilité de l'entreprise.`
    },
    {
        icon: <FiLayers />,
        title: 'Liquidation des sociétes',
        description: `Cela se fait généralement lorsque l'entreprise est insolvable (incapable de payer ses dettes) ou lorsque les actionnaires et les administrateurs décident qu'il nest plus viable ou avantageux de poursuivre ses activités.`
    },
    
    {
        icon: <FiLayers />,
        title: 'Rédaction  des contrats',
        description: `Les contrats se présentent sous différentes formes, mais il existe des étapes et des composants généraux qui s'appliquent à la plupart des contrats.`
    },
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Nos Services' />
               <Header
                headerPosition="header--static logoresize"
                logo="all-dark"
                color="color-black"
                />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Service'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Audit</h2>
                                    {/* <p>Un audit est un examen systématique et indépendant des régistres financiers, des opérations et des contrôles internes d'une organisation, effectué par un auditeur.  but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div >
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Comptabilité</h2>
                                    {/* <p>La tenue de la comptabilité et l'établissement du bilan représente le métier de base 
                                    de l'expert comptable. Cette mission est adaptée à chaque entreprise</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="text-center" >
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Conseil</h2>
                                    {/* <p>CAIRQ CONSEIL fournit des conseils et une assistance professionnels pour aider les organisations à gérer leurs audits financiers, à améliorer les contrôles internes et à assurer la conformité aux exigences réglementaires et légales.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceListThree.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Tax & Légal</h2>
                                    <p>Les questions fiscales et juridiques sont étroitement liées et jouent un rôle essentiel dans l'exploitation et la gestion des entreprises.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceListFour.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;