import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";

import about from "../assets/images/about/about-3.jpg";
import findingImg from "../assets/images/about/finding-us-01.png";
import teamImg1 from "../assets/images/team/team-01.jpg";
import teamImg2 from "../assets/images/team/team-02.jpg";

class About extends Component {
  render() {
    let title = "About",
      description =
        "Cabinet CAIRQ CONSEIL se specialise en audit, comptabilite, tax et conseil";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Qui Nous Sommes"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={about}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">Qui Nous Sommes</h2>
                      <p className="description">Le Cabinet CAIRQ Conseil est dirigé par deux Associés en la personne
de Monsieur Rémy IKONGA et Monsieur Hyppolyte Hilaire QUENUM. Ils
sont Experts Comptables Agréés par la Communauté Economique et
Monétaire de l'Afrique Centrale en sigle CEMAC. Ils ont plus de 
trente ans d'expérience professionelle qu'ils ont acquise au cours
de leurs parcours professionels en ayant travaillé dans des cabinets
internationaux.</p>
                    </div>
                    <div className="row mt--30">
                      
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Notre Mission</h3>
                          <p>
                          CAIRQ Conseil développe des compétences sectorielles spécifiques
pour proposer des solutions adaptées à ses clients dans les
principaux secteurs d'activités économiques et notamment dans 
l'industrie, la technologie, la communication, la distribution, les
loisirs, la santé, le secteur public et l'enseignement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        
        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="color-1">Plus de 
                30 ans d'expérience professionelle</h4>
                <p>
                Depuis plus de 30 ans, les associés offrent des services dans les
                domaines de l'Audit, du Tax et du Conseil.
                </p>
                <a className="rn-btn btn-white" href="/contact">
                  Prêt a nous faire confiance?
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img src={findingImg} alt="Finding Images" />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Notre Equipe</h2>
                  <p>
                    Notre équipe est prête a vous assister dans vos projets.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={teamImg1}
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Remy IKONGA</h4>
                    <p className="designation">Expert Comptable</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={teamImg2}
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Hyppolyte Hilaire QUENUM</h4>
                    <p className="designation">Expert Comptable</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
